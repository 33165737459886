<template>
  <div style="margin-top: 30px">
    <div v-if="newsList.length > 0">
      <el-row v-for="(item, i) in newsList" :key="i" class="list">

        <div v-if="i != 0">
          <div v-if="item.year - newsList[i - 1].year == -1" class="year">
            {{ item.year }}
          </div>
        </div>
        <div v-else class="year">
          {{ item.year }}
        </div>

        <el-col :span="2">
        </el-col>
        <el-col :span="14">
          <router-link :to="{
            path: 'solution',
            name: 'solution',
            query: {
              nid: item.id,
              cid: cid,
              sid: sid,
              degree: degree,
            },
          }" :key="i" class="items">
            <div class="title">{{ item.title }}</div>
          </router-link>
        </el-col>
      </el-row>
    </div>

    <h2 v-else style="text-align: center">暂无信息！</h2>
    <div class="pagination">
      <el-pagination :hide-on-single-page="true" :page-size="pageSize" :total="total" class="page-box" background
        layout="prev, pager, next" @current-change="pageChange" />
    </div>
  </div>
</template>

<script>
//time: 2020/11/6
export default {
  name: "SchoolNews",
  data() {
    return {
      cid: -1,
      sid: -1,
      degree: -1,
      pageNum: 1,
      pageSize: 16,
      total: 0,
      newsList: [],
    };
  },
  created() {
    this.cid = this.$route.query.cid;
    this.sid = this.$route.query.sid;
    this.degree = this.$route.query.degree;
    this.getList();
  },
  methods: {
    getList() {
      this.$fecth
        .post("school_news/getData", {
          school_id: this.$route.query.cid,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((res) => {
          this.total = res.total;
          this.newsList = res.lists;
        });
    },
    pageChange(page) {
      this.pageNum = page;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  color: #6c6b6b;
  border-bottom: 1px dashed #eeeeff;
  line-height: 50px;

  a {
    color: #6c6b6b;
  }
}

.pagination {
  padding: 40px 0;

  .page-box {
    text-align: center;
  }
}

.year {
  color: #519deb;
  font-size: 22px;
}</style>